@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Open+Sans:wght@300;400&display=swap';

:root {
	--theme-primary: #2751a3;
	--theme-light-shade: #e6eaff;
	--theme-light-shade2: #f6f7ff;
	--light-blue-border: #cad2fb;
	--greyLight: #e9eff4;
	--fieldBdrColor: rgb(226, 232, 240);
	--navActive: rgba(84, 111, 255, 0.1);
	--vLightBG: #e6eaff;
	--icon-blue: #546fff;
	--fontOpenS: 'Open Sans', sans-serif;
	--fontInter: 'Inter', sans-serif;
	--lightRed: #EC5F5F;
}
