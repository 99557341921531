/* ===== Commonly used styles for components ===== */

@import '../../global.css';

.sidebar {
	background-color: var(--theme-light-shade2);
}

.sidebar .borderTLB,
.sidebar .borderYLB {
	border-style: solid;
	border-color: var(--light-blue-border);
}
.sidebar .borderTLB {
	border-width: 1px 0 0;
}
.sidebar .borderYLB {
	border-width: 1px 0;
}

.sidebar .navHe8 {
	max-height: calc(100vh - 400px);
	overflow-x: hidden;
	overflow-y: auto;
}

ul.mainNav li,
ul.setNav li {
	margin-bottom: 3px;
	margin-left: 0.75em;
}
ul.mainNav li a,
ul.setNav li a,
ul.mainNav li button,
ul.setNav li button {
	display: block;
	width: 100%;
	text-align: left;
	position: relative;
	padding: 6px 10px 6px 48px;
	border-style: solid;
	border-width: 0 0 0 3px;
	border-color: transparent;
	min-height: 38px;
	color: var(--theme-primary);
	font-size: 0.95rem;
	line-height: 1.6;
}
ul.mainNav li:hover a,
ul.mainNav li.active a,
ul.setNav li:hover a,
ul.setNav li.active a,
ul.mainNav li:hover button,
ul.mainNav li.active button,
ul.setNav li:hover button,
ul.setNav li.active button {
	background-color: var(--navActive);
}
ul.mainNav li.active a,
ul.setNav li.active a,
ul.mainNav li.active button,
ul.setNav li.active button {
	border-color: var(--theme-primary);
	font-weight: 700;
}

ul.mainNav li a > span[class*='material-icons'],
ul.setNav li a > span[class*='material-icons'],
ul.mainNav li button > span[class*='material-icons'],
ul.setNav li button > span[class*='material-icons'] {
	top: 50%;
	left: 8px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

/* After sidebar - White workspace */

button.shHandle {
	position: absolute;
	left: 10px;
	top: -2px;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	font-size: 18px !important;
	line-height: 1.2;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 0;
}
button.shHandle span {
	font-size: 24px !important;
}

.counterBox {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right top;
	background-image: url('../../../public/svg/small-box-style.svg');
	border-left: solid 3px var(--theme-primary);
	min-height: 120px;
}

.dragDropBox {
	background-color: var(--theme-light-shade2);
	border: dashed 1px rgba(0, 0, 0, 0.25);
	-webkit-border-radius: 0.375rem;
	border-radius: 0.375rem;
}

.vlFrame {
	background-color: var(--fieldGreyBGColor);
	max-height: calc(100vh - 50px);
}

.listAccWrap {
	max-height: calc(100vh - 332px);
}

.proActive {
	background-color: var(--navActive);
	border-left: solid 3px var(--theme-primary);
}

.profBox {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right top;
	background-image: url('../../../public/svg/profBoxBG.svg');
	border-left: solid 3px var(--theme-primary);
	min-height: 120px;
}

.List {
	width: 1400px !important;
	height: auto !important;
	max-width: auto !important;
}

.infoMessage { 
	left:50%;
	transform: translateX(-50%)
}

@media all and (min-width: 768px) {
	.outLetWd {
		width: calc(100% - 16rem);
	}
}


/* ====== Validation Fields ======= */

.fldsBoxWrap {
	width: 98%;
	height: calc(50vh - 180px);
}
.fldsBoxTable td {
	padding: 4px;
}

/* === Notifications ==== */

.notiCount {
	position: absolute;
	top: 50%;
	right: 1.25rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	-webkit-border-radius: 50rem;
	border-radius: 50rem;
	background-color: var(--theme-primary);
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-style: normal;
}

.notifyPanel {
	height: calc(100vh - 155px);
}
.unReadTip {
	width: 8px;
	height: 8px;
	-webkit-border-radius: 50rem;
	border-radius: 50rem;
	background-color: var(--lightRed);
	display: block;
}

table.csvTable tr {
	border-bottom: solid 1px #c2c2c2;
}
table.csvTable th,
table.csvTable td {
	padding: 4px 8px;
	vertical-align: top;
	font-size: 12px;	
}

/* Global Chatbot styles */

.botIconBG {
	width: 110px;
	height: 140px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-image: url('../../../public/svg/bot-icon-orange.svg');
	position: fixed;
	right: 0px;
	bottom: -66px;
	z-index: 999;
}
.botIcon {
	width: 60px;
	height: 55px;
	display: block;
	margin: 2px 26px 0 auto;
}


.fldsBoxWrap {
	width: 98%;
	height: calc(100vh - 350px);
}
.fldsBoxTable td {
	padding: 4px;
}

/* === Notifications ==== */

.notiCount {
	position: absolute;
	top: 50%;
	right: 1.25rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	-webkit-border-radius: 50rem;
	border-radius: 50rem;
	background-color: var(--theme-primary);
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-style: normal;
}

.notifyPanel {
	height: calc(100vh - 155px);
}
.unReadTip {
	width: 8px;
	height: 8px;
	-webkit-border-radius: 50rem;
	border-radius: 50rem;
	background-color: var(--lightRed);
	display: block;
}

table.csvTable tr {
	border-bottom: solid 1px #c2c2c2;
}
table.csvTable th,
table.csvTable td {
	padding: 4px 8px;
	vertical-align: top;
	font-size: 12px;	
}