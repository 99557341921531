@tailwind base;
@tailwind components;
@tailwind utilities;

@import './gvar.css';

/* Some CSS modules customization */

/* body div[class*='MuiStack'] {
    padding: 0;
    width: 150px;
    overflow: hidden;
} */
body div[class*='MuiStack'] input[class*='MuiInputBase-input'] {
	padding: 10px 12px;
	font-size: 0.8rem;
	line-height: 1.6;
	display: flex;
	order: 2;
}

body div[class*='MuiStack'] div[class*='MuiInputBase-root'] {
	font-family: inherit;
	padding-right: 0;
}

body div[class*='MuiStack'] div[class*='MuiInputAdornment-root'] {
	order: 1;
	margin-left: 0;
	padding-right: 6px;
}

body .dateHldr div[class*='MuiFormControl-root'] input[class*='MuiInputBase-input']{
	padding-left: 40px;
	width: 110px;
}

body .dateHldr button[class*='MuiIconButton-edgeEnd']{
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);

}

body .dateHldr button[class*='clearButton']{
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);

}

body div[class*='MuiStack'] .MuiTextField-root {
	min-width: 150px;
}

body nav[class*='MuiList-root'] {
	background-color: var(--theme-primary);
	padding: 0;
	border-radius: 0.375rem;
	cursor: pointer;
}

body nav[class*='MuiList-root']:hover {
	background-color: rgb(67, 56, 202);
}

body nav[class*='MuiList-root'] div[class*='MuiListItemText-root'] {
	margin: 0;
}

body nav[class*='MuiList-root'] div[class*='MuiListItemText-root'] > span,
body nav[class*='MuiList-root'] div[class*='MuiListItemText-root'] > p {
	display: inline-block;
	vertical-align: middle;
	color: #ffffff;
	font-weight: 700;
	font-family: var(--fontInter);
}

body
	nav[class*='MuiList-root']
	div[class*='MuiListItemText-root']
	.material-icons {
	font-size: 20px;
	line-height: 16px;
	padding: 0;
}

body nav[class*='MuiList-root'] div[class*='MuiListItemText-root'] > span {
	height: 18px;
}

body nav[class*='MuiList-root'] div[class*='MuiListItemText-root'] > p {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

body div[class*='MuiAccordion-root'] {
	-webkit-box-shadow: 0 0 0 transparent;
	box-shadow: 0 0 0 transparent;
	border-bottom: solid 1px #e5e7eb !important;
}

body div[class*='MuiAccordion-root'],
body div[class*='MuiAccordionDetails-root'],
body div[class*='MuiAccordion-root']:first-of-type,
body div[class*='MuiAccordion-root']:last-of-type {
	-webkit-border-radius: 0;
	border-radius: 0;
}

body div[class*='MuiAccordion-root'].Mui-expanded {
	margin: 0;
	flex-grow: 1;
}

body div[class*='MuiAccordion-root']:before {
	display: none;
}

body div[class*='MuiAccordionSummary-content'] {
	padding-top: 8px;
	padding-bottom: 8px;
	margin: 0;
}

body div[class*='MuiAccordionSummary-root'],
body div[class*='MuiAccordionSummary-root'].Mui-expanded {
	min-height: 0;
}

body div[class*='MuiAccordionSummary-root'].Mui-expanded {
	background-color: var(--greyLight);
}

body div[class*='MuiAccordionSummary-content'].Mui-expanded {
	margin: 0;
}

body .lightT nav[class*='MuiList-root'] {
	background-color: #ffffff;
	border: solid 1px var(--fieldBdrColor);
	outline: solid 2px transparent;
	color: rgba(0, 0, 0, 0.7);
}

body .lightT nav[class*='MuiList-root']:hover {
	outline-color: rgb(96 165 250);
}

body .lightT nav[class*='MuiList-root'] div[class*='MuiListItemText-root'] > p {
	color: rgba(0, 0, 0, 0.7);
	font-weight: 400;
}

body .lightT nav[class*='MuiList-root'] li[class*='MuiListItem-root'] {
	padding-right: 8px;
}

body .react-tabs__tab-list {
	border: none;
	user-select: none;
}

body .react-tabs__tab {
	bottom: 0;
	border-style: solid;
	border-width: 0 0 3px;
	border-color: transparent;
	padding-left: 0;
	padding-right: 0;
	margin-right: 2rem;
}

body .react-tabs__tab.react-tabs__tab--selected {
	border-color: var(--theme-primary);
	color: var(--theme-primary);
	font-weight: 700;
	bottom: 0;
	margin-bottom: 0;
}

body .react-tabs__tab.react-tabs__tab--selected::after {
	display: none;
}

body .react-tabs__tab-panel {
	padding-top: 1rem;
}

body .material-icons.text-sm {
	font-size: 1.2rem;
}

body .dotlottie-container.main {
	width: 54px;
	height: 54px;
	display: block;
	-webkit-filter: saturate(1.55);
	filter: saturate(1.55);
}

body nav[class*='MuiList-root'].outlineNav {
	background-color: transparent;
	border: solid 1px var(--theme-primary);
	color: var(--theme-primary);
}

body nav[class*='MuiList-root'].outlineNav:focus,
body nav[class*='MuiList-root'].outlineNav:hover {
	background-color: var(--theme-primary);
	color: #ffffff;
}

body
	nav[class*='MuiList-root'].outlineNav
	div[class*='MuiListItemText-root']
	> span,
body
	nav[class*='MuiList-root'].outlineNav
	div[class*='MuiListItemText-root']
	> p {
	color: var(--theme-primary);
}

body
	nav[class*='MuiList-root'].outlineNav:hover
	div[class*='MuiListItemText-root']
	> span,
body
	nav[class*='MuiList-root'].outlineNav:hover
	div[class*='MuiListItemText-root']
	> p,
body
	nav[class*='MuiList-root'].outlineNav:focus
	div[class*='MuiListItemText-root']
	> span,
body
	nav[class*='MuiList-root'].outlineNav:focus
	div[class*='MuiListItemText-root']
	> p {
	color: #ffffff;
}

body nav[class*='MuiList-root'].outlineNav li[class*='MuiListItem-root'] {
	padding-top: 6px;
	padding-bottom: 6px;
}

body .invoiceAcc div[class*='MuiAccordionDetails-root'] {
	padding: 0;
}

body .thinDD div[class*='MuiOutlinedInput-input'].MuiSelect-select {
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 12px;
	font-size: 12px;
}

body .thinDD label[class*='MuiFormLabel-root'] {
	font-family: inherit;
	font-size: 0.85rem;
	line-height: 1.2em;
}

form div[class*='mb'] .error {
	color: #ff0000;
	display: inline-block;
}

/* ====== Media Query ====== */
@media all and (min-width: 768px) {
	body #client-menu div[class*='MuiMenu-paper'] {
		width: 320px;
	}
}

@media all and (min-width: 1200px) {
	body #client-menu div[class*='MuiMenu-paper'] {
		width: 580px;
	}
}

.delBBBtn,
.bbNum {
	position: absolute;
	z-index: 2;
	font-weight: 700;
	text-shadow: 0 0 2px #ffffff;
	line-height: 1;
}

.delBBBtn {
	color: #47a6ff;
	right: 36px;
	font-size: 18px;
	top: -25px;
}

.bbNum {
	color: #094782;
	left: 22px;
	font-size: 12px;
	top: 14px;
	display: none;
}

.areaBoxOuter {
	min-width: 190px;
	position: absolute;
	z-index: 2;
}

.bbDDBoxMain {
	position: absolute;
	bottom: 100%;
	left: -2px;
	background-color: rgba(255, 255, 255, 0.9);
	min-width: 120px;
	z-index: 9999 !important;
}

.bbDDBoxMain .bbDAr.material-icons {
	color: #47a6ff;
	right: 4px;
	font-size: 12px;
	font-size: 22px;
	position: absolute;
	top: 2px;
	right: 4px;
	line-height: 1;
}

.bbDDBoxMain .ddLbl {
	line-height: 1.3;
	font-size: 12px;
}

.bbDDownBox {
	position: absolute;
	top: 25px;
	left: 0;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
	max-height: 200px;
	overflow: auto;
	z-index: 999;
}

.ddBxHdr {
	border: solid 3px #47a6ff;
}

.ddBxHdr .ddLbl {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 130px;
	padding-right: 20px;
}

.bbDDownBox .fItem {
	border-top: solid 1px #c2c2c2;
}

.canvasBB div[data-ord] {
	display: none !important;
}

/* Swal Alert */

.swal-modal .swal-text:first-child {
	margin-top: 30px;
}

.swal-modal .swal-text {
	font-weight: 400;
	line-height: 1.6;
	color: #000000;
}

.swal-modal .swal-footer {
	text-align: center;
}

.swal-modal .swal-button.swal-button--cancel {
	background-color: #efefef;
}

.swal-modal .swal-button {
	background-color: var(--theme-primary);
}

body div[class*='MuiAutocomplete-root'] .MuiOutlinedInput-root,
body div[class*='MuiAutocomplete-root'].expCat .MuiOutlinedInput-root {
	padding-top: 2px;
	padding-bottom: 2px;
}

body div[class*='MuiFormControl-root'] .MuiSelect-select {
	padding: 10px 12px;
}

body label[class*='MuiInputLabel-root']:not(.MuiInputLabel-shrink) {
	-webkit-transform: translate(14px, 10px) scale(1);
	transform: translate(14px, 10px) scale(1);
}

body div[class*='MuiAutocomplete-root'].expCat label[class*='MuiInputLabel-root'] {
	font-size: 12px;
	font-weight: 500;
}
body div[class*='MuiAutocomplete-root'].expCat .MuiOutlinedInput-root .MuiAutocomplete-input {
	padding-top: 4px;
	padding-bottom: 4px;
	height: 28px;
	font-size: 14px;
}

body div[class*='MuiOutlinedInput-notchedOutline'] {
	border-color: rgb(226 232 240);
}

.material-icons-outlined.modalClose,
.material-icons.modalClose {
	font-size: 32px;
}

body div[class*='MuiFormControl-root'] {
	display: flex;
}

body div[class*='MuiFormControl-root'] input[class*='MuiInputBase-input'] {
	padding: 10px 14px;
	font-family: inherit;
}

.leading-none .material-icons-outlined {
	font-size: 20px;
}

.maxWidth {
	max-width: 1400px;
}

/* .openedSidebar~.grow .maxWidth {
    max-width: 670px;
} */

.openedSidebar ~ button[class*='shHandle'] {
	top: 2px;
	left: 240px;
	padding: 0 !important;
	-webkit-filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
	background-color: #ffffff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.pagination button.active {
	background-color: var(--theme-primary);
	color: #ffffff;
}

.maxWFN {
	max-width: 110px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

body button[class*='MuiButtonBase-root'] {
	font-family: inherit !important;
	text-transform: capitalize !important;
	font-size: 13px;
	font-weight: 500 !important;
}

body button[class*='MuiButtonBase-root'].Mui-selected {
	color: var(--theme-primary) !important;
	font-weight: 700 !important;
}

body .uplDocAcc div[class*='MuiAccordionDetails-root'] {
	padding: 6px 0 0 !important;
}

body .bg-blue-100 {
	background-color: var(--theme-light-shade2);
}

.tblLoop:hover > .grow.border.rounded-md {
	background-color: var(--vLightBG);
}

.tblLoop:hover > .grow.border.rounded-md,
.tblLoop:hover > .flex-none:not(.nonShadow) .vLBG,
.tblLoop:hover > .flex-none:not(.nonShadow) button {
	box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.3);
}

button:not(:disabled) > label {
	cursor: pointer;
}

body ul[class*='MuiList-root'].rolleDDBtn {
	padding: 0;
	-webkit-border-radius: 0 6px 6px 0 !important;
	border-radius: 0 6px 6px 0 !important;
}
body .rolleDDBtn div[class*='MuiListItemButton-root'] {
	background-color: transparent !important;
	-webkit-border-radius: 0 !important;
	border-radius: 0 !important;
	padding: 6px 0 6px 16px;
	font: inherit !important;
	color: #424242 !important;
}
body .rolleDDBtn span[class*='MuiTouchRipple'] {
	display: none !important;
}
body .roleDD div[class*='MuiPopover-paper'] {
	min-width: 200px;
	-webkit-transform: translate(0, 8px) !important;
	transform: translate(0, 8px) !important;
}
body .roleDD ul.MuiMenu-list li,
body .MuiList-root li[class*='MuiMenuItem-root'] {
	position: relative;
	font-family: inherit;
}
body .roleDD ul.MuiMenu-list li::after,
body .MuiList-root li[class*='MuiMenuItem-root']::after {
	content: '';
	width: 94%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.1);
	position: absolute;
	left: 50%;
	bottom: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
body .roleDD ul.MuiMenu-list li:last-child::after,
body .MuiList-root li[class*='MuiMenuItem-root']:last-child::after {
	display: none;
}
body .roleDD ul.MuiMenu-list li.Mui-selected {
	font-weight: 600;
}
body .roleDD ul.MuiMenu-list li[class*='MuiMenuItem-root'].Mui-selected {
	background-color: var(--theme-light-shade2) !important;
}

.chkBtnCHldr {
	position: relative;
	width: 16px;
	height: 16px;
}
.chkBtnC {
	width: 16px;
	height: 16px;
	background-color: #d2d2d2;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.chkBtnCHldr > input {
	z-index: 4;
	opacity: 0;
}
.chkBtnCHldr > input,
.chkBtnCHldr > .chkBtnC {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 100%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.chkBtnCHldr > input:checked + .chkBtnC {
	/* background-color: #000000; */
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url('../public/svg/checked-circle.svg');
}

body .msDD div[class*='MuiOutlinedInput-root'] {
	font: inherit;
	width: 100%;
	max-width: 401px;
	border: none;
	outline: none;
}
body .msDD fieldset[class*='MuiOutlinedInput'] {
	border: none !important;
}
body .msDD div[class*='MuiOutlinedInput-input'] {
	padding: 10px 32px 10px 16px;
}
body span[class*='MuiCheckbox-root'] {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	padding-left: 0 !important;
}
body span[class*='MuiCheckbox-root'].Mui-checked,
body span[class*='MuiCheckbox-root'].MuiCheckbox-indeterminate {
	color: #2751a3;
}

body .cdRow.relative > .absolute {
	position: absolute !important;
	color: rgba(0, 0, 0, 0.4);
}
body .cdRow div[class*='MuiListItemText-root'] {
	background-color: #ffffff;
	margin-left: -2px;
	padding-left: 2px;
}

body button:disabled span.material-icons {
	color: #424242;
}

body .fldWrap .react-tabs__tab-panel {
	padding-top: 0;
}

.listDHeight {
	height: 400px;
	margin-bottom: 14px;
}

.listDHeightFld {
	height: 400px;
	margin-bottom: 4px;
}

/* .tblLoop div,
.tblLoop div button.text-sm {
	@apply text-xs;
}
.tblLoop div.grow.py-3 {
	@apply py-2;
}
.tblLoop .flex-none .vLBG.h-\[53px\] {
	@apply h-[48px];
} */

.listDHeight .tblLoop,
.listDHeightFld .tblLoop {
	position: relative;
}
.listDHeight .tblLoop .text-sm {
	font-size: 13px;
}
.listDHeight > div.flex:nth-of-type(1) > div.flex-none:last-of-type,
.tblLoop > .grow.border + .flex-none {
	position: sticky;
	background-color: #ffffff;
	right: 0;
	top: 0;
}

.errorsSlider .slick-prev.slick-disabled,
.errorsSlider .slick-next.slick-disabled {
	opacity: 0.3;
	cursor: default;
	pointer-events: none;
}

/* Media */
@media all and (max-width: 980px) {
	body .manageFlds .top-\[280px\] {
		top: 300px;
	}
}
@media all and (min-width: 981px) {
	body .roleDD div[class*='MuiPopover-paper'] {
		width: 400px;
	}
}
@media all and (min-width: 768px) and (max-width: 1199px) {
	.lg\:flex.vLBG.py-10,
	.lg\:flex.vLBG.py-10.min-h-\[292px\] {
		min-height: 0 !important;
		height: 240px !important;
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
	.lg\:flex.vLBG.py-10 > div {
		width: 100%;
	}
	body .listDHeight {
		height: calc(100vh - 200px);
	}
	
	body .listDHeightFld {
		height: calc(100vh - 364px);
	}
	body ul.lg\:top-\[150px\] {
		top: 148px;
	}
}
@media all and (min-width: 1200px) {
	body .listDHeight {
		height: calc(100vh - 180px);
	}	
	body .listDHeight.misM {
		height: calc(100vh - 300px);
	}	
	body .listDHeight.he8L {
		height: calc(100vh - 260px);
	}
	body .listDHeight.he8L.t2 {
		height: calc(100vh - 220px);
	}	
	body .listDHeightFld {
		height: calc(100vh - 220px);
	}
	body .manageFlds ul.lg\:top-\[150px\] {
		top: 110px;
	}
	body .relative.my-6.lg\:w-\[800px\] .shadow-lg {
		min-height: 332px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	/* body .manageFlds .xl\:min-h-\[100px\].vLBG {
		min-height: 292px;
	} */
}
@media all and (min-width: 1400px) {	
	body .manageFlds .xl\:min-h-\[100px\].vLBG {
		min-height: 100px;
	}
	body .listDHeight:not(.larger) {
		overflow-x: hidden;
	}
	body .listDHeight.misM {
		height: calc(100vh - 260px);
	}
}
@media all and (max-width: 1023px) {
	.App div[class*='react-split'] {
		position: static !important;
		width: 100% !important;
		height: auto !important;
		overflow: visible !important;
	}
	.App div[class*='react-split'] > div {
		border: none !important;
	}
	.App .List > div {
		max-height: 65vh !important;
	}
}

.bbDDownBox .fItem span[class*='invfldName'] {
	position: static;
	width: 170px;
	font-size: 12px;
}

.listDHeight label.font-black.relative,
.listDHeightFld label.font-black.relative {
	padding-right: 20px;
}

.listDHeight .grow .border-r,
.listDHeightFld .grow .border-r {
	border-right-width: 0;
}

.true_size_error {
	border-color: red;
}

body .material-icons {
	font-weight: 700;
}

div[class*='boxTableCtnr'] .leading-none .material-icons-outlined,
div[class*='invfieldsExtractedWrap'] .leading-none .material-icons-outlined {
	font-size: 16px;
	line-height: 1;
}
.errorsSlider > .slick-slider {
	padding-left: 0;
	padding-right: 0;
}
.errorsSlider .slick-track {
	margin-left: 0 !important;
}
.errorsSlider .slick-prev {
	right: 40px;
	left: auto;
}
.errorsSlider .slick-next {
	right: 0;
	-webkit-transform: translateY(-50%) scale(-1);
	transform: translateY(-50%) scale(-1);
}
.errorsSlider .slick-prev::before,
.errorsSlider .slick-next::before {
	content: '';
	display: none;
}
.errorsSlider .slick-prev,
.errorsSlider .slick-next {
	background-image: url('../public/svg/carousel-arrow.svg') !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background-position: center;
	position: absolute;
	top: -16px;
}

body .material-icons.text-sm.moreIco {
	line-height: 1.2;
}

.prevPBtn,
.nextPBtn {
	display: inline-block;
	width: 32px;
	height: 32px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-image: url('../public/svg/carousel-arrow.svg') !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background-position: center;
}
.nextPBtn {
	-webkit-transform: scale(-1);
	transform: scale(-1);
}

span.absolute.text-ellipsis > div {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

@media all and (min-width: 1400px) {
	.listDHeight.overflow-auto > .flex.r20:not(.tblLoop) {
		margin-right: 20px;
	}
	body .swal-modal {
		width: 590px;
	}
}


.material-icons-outlined.text-xs,
.material-icons.text-xs {
	font-size: 16px;
}

body .expMenu .MuiList-root li[class*='MuiMenuItem-root']:hover {
	color: var(--theme-primary) !important;
	font-weight: 600;
}
.expMenu .MuiList-root li[class*='MuiMenuItem-root'] .material-icons {
	opacity: 0.5;
}
.expMenu .MuiList-root li[class*='MuiMenuItem-root']:hover .material-icons {
	opacity: 1;
}


input[type='number'].appearance-none::-webkit-outer-spin-button,
input[type='number'].appearance-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

input[type='number'].appearance-none {
  -moz-appearance: textfield;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body .areaBoxOuter:not(.clicked) ~ div {
  	display: none !important;
}

body .buildStat span.material-icons-outlined {
	font-size: 1rem;
	font-weight: 600;
	margin-right: 6px;
}

body .tblLoop span[class*='MuiCheckbox-root'].Mui-disabled {
	pointer-events: all;
	cursor: default;
}

body .swal-modal .swal-title {
	font-weight: 400;
	color: var(--theme-primary);
}

.linearPeakText {
	position:absolute;
	width: 100%;
	height: 100%;
	left:0;
	top:0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}

.relative .MuiLinearProgress-root span[class*='MuiLinearProgress-bar'] {
	background-color: var(--theme-primary) !important;
}

body .MuiList-root li[class*='MuiMenuItem-root'].radioDD {
	padding-left: 46px;
}
body .MuiList-root li[class*='MuiMenuItem-root'].radioDD::before {
	content: '';
	width: 18px;
	height: 18px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #d9d9d9;
	position: absolute;
	left: 1rem;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
body .MuiList-root li[class*='MuiMenuItem-root'].radioDD.Mui-selected::before {
	background-image: url('../public/svg/checked-radio-tblue.svg');
	background-color: #ffffff;
}

.hContainer { 
	height: calc(100vh - 240px)
}

.bgGrayLight {
	background-color: var(--greyLight);
}

.exclamation-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('../public/svg/exclamation.svg')
}


body .loopChild span[class*='MuiCheckbox-root'] {
	transform: translateX(7px);
}

.listDHeight .text-sm {
	font-size: 13px;
}

/* Chat Loader */
.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
	box-shadow: 9999px 0 0 -5px;
	animation: dot-pulse 1.5s infinite linear;
	animation-delay: 0.25s;
  }
  .dot-pulse::before, .dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #9880ff;
	color: #9880ff;
  }
  .dot-pulse::before {
	box-shadow: 9984px 0 0 -5px;
	animation: dot-pulse-before 1.5s infinite linear;
	animation-delay: 0s;
  }
  .dot-pulse::after {
	box-shadow: 10014px 0 0 -5px;
	animation: dot-pulse-after 1.5s infinite linear;
	animation-delay: 0.5s;
  }
  
  @keyframes dot-pulse-before {
	0% {
	  box-shadow: 9984px 0 0 -5px;
	}
	30% {
	  box-shadow: 9984px 0 0 2px;
	}
	60%, 100% {
	  box-shadow: 9984px 0 0 -5px;
	}
  }
  @keyframes dot-pulse {
	0% {
	  box-shadow: 9999px 0 0 -5px;
	}
	30% {
	  box-shadow: 9999px 0 0 2px;
	}
	60%, 100% {
	  box-shadow: 9999px 0 0 -5px;
	}
  }
  @keyframes dot-pulse-after {
	0% {
	  box-shadow: 10014px 0 0 -5px;
	}
	30% {
	  box-shadow: 10014px 0 0 2px;
	}
	60%, 100% {
	  box-shadow: 10014px 0 0 -5px;
	}
  }


.donCanVas {
	position: relative;
}
.donCanVas label.donCLbl {
	display: block;
	position: absolute;
	z-index: -10;
	left: 50%;
	top: calc(50% + 50px);
	transform: translate(-50%, -50%);
	font-size: 14px;
	color: rgb(2, 197, 68);
}

body #batch .MuiPopper-root li:first-child { 
	position: relative; 
	padding-right: 48px; 
	background-color: rgba(24, 238, 31, 0.15); 
}

body #batch .MuiPopper-root li { 
	font-size: 14px;
}

span.nTag {
	display: inline-block;
	position: absolute;
	right: 8px;
	top: 50%;
	padding: 1px 4px;
	background-color: var(--theme-primary);
	color: #ffffff;
	font-size: 10px;
	-webkit-border-radius: 50rem;
	border-radius: 50rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}


/* .h-screen.overflow-y-auto.fontInter::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
.h-screen.overflow-y-auto.fontInter::-webkit-scrollbar-thumb {
	background: transparent;
}
.h-screen.overflow-y-auto.fontInter::-webkit-scrollbar-track {
	background: transparent;
} */


body .missDD div[class*='MuiAutocomplete-root'].expCat .MuiOutlinedInput-root .MuiAutocomplete-input {
	font-size: 12px !important;
	height: 26px;
}
.missDD, .missDD li {
	font-size: 12px !important;
}
body .missDD div[class*='MuiAutocomplete-root'].expCat .MuiOutlinedInput-root {
	padding-right: 30px !important;
}
.missDD svg[class*='MuiSvgIcon'] {
	font-size: 12px;
	width: 12px;
	height: 12px;
}
body .missDD button[class*='MuiButtonBase-root'] {
	font-size: 12px;
}
body .missDD div[class*='MuiStack'] .MuiTextField-root {
	min-width: 135px;
}
body .missDD.dateHldr div[class*='MuiFormControl-root'] input[class*='MuiInputBase-input'] {
	padding-left: 30px;
    width: 74px;
	font-size: 12px;
}
body .missDD div[class*='MuiAutocomplete-root'].expCat label[class*='MuiInputLabel-root'] {
	padding-right: 10px;
}
body .MuiDayCalendar-root button[class*='MuiButtonBase-root'].Mui-selected {
	color: #ffffff !important;
}

.tblLoop > .grow.border + .flex-none button {
	display: flex;
	align-items: center;
	justify-content: center;
}

body .missDD div[class*='MuiAutocomplete-root'].expCat label[class*='MuiInputLabel-root'] {
    padding-right: 10px;
}
body div[class*='MuiAutocomplete-root'].expCat label[class*='MuiInputLabel-root'] {
	font-size: 12px;
	font-weight: 500;
}
body .missDD div[class*='MuiAutocomplete-root'].expCat .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 12px !important;
    height: 26px;	
}

body .missDD div[class*='MuiPopper-root'] {
	white-space: normal;
	word-break: break-all;
}

.listDHeight label.font-black.relative.pr-0, 
.listDHeightFld label.font-black.relative.pr-0 {
	padding-right: 0;
	padding-top: 12px;
}

@media all and (min-width: 1600px) {
	.largeFltr .flex-none.w-32,
	.largeFltr .flex-none.w-36 {
		width: 170px;
	}
	body div[class*='MuiAutocomplete-root'].expCat label[class*='MuiInputLabel-root'],
	body .missDD, body .missDD li {
		font-size: 14px !important;
	}
	body div[class*='MuiAutocomplete-root'].expCat .MuiOutlinedInput-root .MuiAutocomplete-input {
		padding-top: 6px;
		padding-bottom: 6px;
		height: 38px;
		font-size: 14px;
	}
	.largeFltr .flex-none.w-32 .lightT .h-\[38px\] {
		height: 42px;
	}
	
}
@media all and (min-width: 1900px) {
	.largeFltr .flex-none.w-32,
	.largeFltr .flex-none.w-36 {
		width: 205px;
	}
}
@media all and (min-width: 2200px) {
	.largeFltr .flex-none.w-32,
	.largeFltr .flex-none.w-36 {
		width: 275px;
	}
}
@media all and (min-width: 2600px) {
	.largeFltr .flex-none.w-32,
	.largeFltr .flex-none.w-36 {
		width: 290px;
	}
}
@media all and (min-width: 3200px) {
	.largeFltr .flex-none.w-32,
	.largeFltr .flex-none.w-36 {
		width: 340px;
	}
}


.justify-between.items-center.vLBG.py-4.px-6 > div:nth-of-type(1) {
	position: relative;
	min-height: 68px;
	flex-grow: 1;
}
.justify-between.items-center.vLBG.py-4.px-6 > div:nth-of-type(2) {
	flex: none;
	width: 640px;
	display: flex;
	justify-content: flex-end;
}
.justify-between.items-center.vLBG.py-4.px-6 > div:nth-of-type(1) > h1 + p.text-xs.text-gray-500 {
	position: absolute;
	left: 0;
	top: 34px;
	width: 100%;
	white-space: normal;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

p.text-xs.text-gray-500.h-9 {
	overflow: hidden;
}

 
body .areaBoxOuter:not(.clicked) .bbDDBoxMain,
body .areaBoxOuter:not(.clicked) .delBBBtn{
	display: none !important;
}

body .clearAnn nav[class*='MuiList-root'] {
	background-color: rgba(255, 0, 0, 0.1);
	color: rgba(255, 0, 0, 0.6);
}
#clear-annotations ul[class*='MuiMenu-list'] {
	width: 178px;
}
#clear-annotations ul[class*='MuiMenu-list'] > li:hover {
	background-color: rgba(255, 0, 0, 0.1);
	color: rgba(255, 0, 0, 0.7);
	font-weight: 600;
}

/* === Validations === */
.validFld div[class*='MuiSelect-root'] {
	width: 100%;
	display: flex;
}

.validFld div[class*='MuiOutlinedInput-input'] {
	padding: 5px 32px 5px 14px;
	min-height: 0;
	font-size: 14px;
}

.validFld > div[class*='MuiInputBase-root'] {
	width: 100%;
}

table[class*='fldsBoxTable'] td > label,
table[class*='fldsBoxTable'] td > .validFld {
	position: relative;
	display: block;
}
table[class*='fldsBoxTable'] td > label::before,
table[class*='fldsBoxTable'] td > .validFld::before {
	content: '';
	width: 3px;
	height: 100%;
	position: absolute;
	z-index: 3;
	left: 0;
	top: 0;
	background-color: #8b8b8b;
	border-radius: 6px 0 0 6px;
}

.react-split.annotationsWithTopBox {
	height: 78%;
}
.react-split.annotationsContWithTopBox {
	height: 87%;
}

.annotationsContWithTopBox div[class*='invFieldsWrap'] {
	max-height: calc(100vh - 344px);
}

div[class*='MuiAccordionDetails-root'] div[class*='chatWrapOuter'] {
	height: calc(100vh - 319px);
    overflow: auto;
}



div[id*='box'] div[data-wrapper].noLable {
	border-color: red !important;
	}

div[class*='MuiAccordionDetails-root'] div[class*='chatWrapOuter'] {
	height: calc(100vh - 319px);
    overflow: auto;
}

div[id*='box'] div[data-wrapper].noLable {
	border-color: red !important;
}
