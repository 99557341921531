/* ===== Globally used styles ===== */

@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Open+Sans:wght@300;400&display=swap';
@import 'https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round';

:root {
	--theme-primary: #2751a3;
	--theme-light-shade: #e6eaff;
	--theme-light-shade2: #f6f7ff;
	--light-blue-border: #cad2fb;
	--navActive: rgba(84, 111, 255, 0.1);
	--vLightBG: #e6eaff;
	--icon-blue: #546fff;
	--fieldBdrColor: rgb(226, 232, 240);
	--fieldGreyBGColor: rgb(242, 242, 247);
	--fontOpenS: 'Open Sans', sans-serif;
	--fontInter: 'Inter', sans-serif;
	--scrollbar-foreground: #f1f1f1;
	--scrollbar-background: #8c9fe1;
	/* scrollbar-color: var(--scrollbar-background) var(--scrollbar-foreground);
	scrollbar-width: thin; */
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	background: var(--scrollbar-background);
}
::-webkit-scrollbar-track {
	background: var(--scrollbar-foreground);
}

.material-icons,
.material-icons-outlined {
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}

.material-icons {
	font-family: 'Material Icons';
}
.material-icons-outlined {
	font-family: 'Material Icons Outlined';
}

.textUBdr::before {
	content: '';
	width: 30px;
	height: 3px;
	background-color: var(--theme-primary);
	position: absolute;
	left: 0;
	top: -5px;
}

body .text-primary {
	color: var(--theme-primary);
}

span.proIco {
	display: flex;
	flex-direction: column;
	width: 48px;
	height: 48px;
	background-color: var(--theme-primary);
	color: #ffffff;
	font-size: 1.6rem;
	font-weight: 700;
	align-items: center;
	justify-content: center;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	text-transform: uppercase;
}
span.proIco.small {
	width: 32px;
	height: 32px;
	background-color: var(--light-blue-border);
	color: var(--theme-primary);
	font-size: 1rem;
}

.vLBG,
button.vLBG {
	background-color: var(--vLightBG);
}

.vLGBG,
button.vLGBG {
	background-color: var(--fieldBdrColor);
}

.fontInter {
	font-family: var(--fontInter);
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body strong,
body .font-black {
	font-weight: 700;
}

.cIco {
	display: inline-block;
	width: 18px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: contain;
}
button:hover > .cIco {
	-webkit-filter: grayscale(1) brightness(100);
	filter: grayscale(1) brightness(100);
}
.cIco.createBoxIco {
	background-image: url('../public/svg/create_box_ico.svg');
	transform: translateY(3px);
}

.material-icons-outlined.text-lg,
.material-icons.text-lg {
	font-size: 48px;
}

.dateHldr div[class*='MuiStack-root'] {
	overflow: visible !important;
	padding: 0;
}
