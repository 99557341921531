@import url('../../../global.css');

.authSide {
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;
	background-image: url('../../../../public/svg/log-sidebar-shadow.svg');
	font-family: var(--fontInter);
}

.authCopy {
	position: absolute;
	left: 0;
	bottom: 1rem;
	width: 100%;
}

@media all and (min-width: 1024x) and (max-width: 1199px) {
	.authSide {
		background-size: 115% auto;
	}
}
@media all and (min-width: 1024px) {
	.authCopy {
		text-align: left;
		left: 1rem;
	}
}
