/* ===== styles for invoices ===== */

@import '../../global.css';

.invFieldsWrap {
	max-height: calc(100vh - 244px);
}
.invFieldsWrap.price { 
	max-height: calc(100vh - 170px);
}
.chatWrapOuter {
	height: calc(100vh - 209px);
}
.chatWrap {
	height: calc(100vh - 309px);
	overflow-y: auto;
}

.invfieldsExtractedWrap {
	max-height: calc(100vh - 384px);
}

.invfldName {
	position: absolute;
	padding-left: 0.9rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 90%;
	font-size: 12px;
	font-weight: 700;
}
.invfldName::before {
	content: '';
	width: 4px;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	-webkit-border-radius: 50rem;
	border-radius: 50rem;
}

.invFldInput {
	border: solid 1px rgba(0, 0, 0, 0.4);
	padding: 4px 8px;
	font-size: inherit;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	outline: none;
	width: 100%;
	user-select: all;
	font-size: 12px;
}
.invFldInput:read-only {
	border-color: transparent;
}
.invFldInput.red {
	border-color: rgba(255, 4, 4, 0.571);
}

/* Label colors */

.invfldName.gray::before {
	background-color: rgba(0, 0, 0, 0.5);
}
.invfldName.red::before {
	background-color: rgba(255, 0, 0, 0.784);
}
.invfldName.green::before {
	background-color: rgba(31, 100, 103, 1);
}
.invfldName.orange::before {
	background-color: rgba(195, 119, 50, 1);
}
.invfldName.yellow::before {
	background-color: rgba(204, 176, 70, 1);
}
.invfldName.purple::before {
	background-color: rgba(137, 47, 145, 1);
}
.invfldName.pink::before {
	background-color: rgba(199, 17, 215, 1);
}

.invFltr {
	position: absolute;
	right: -9px;
	top: 0;
	z-index: 1;
	cursor: pointer;
	display: none;
}

.invfldHLayer {
	position: relative;
}
.invfldHLayer:hover > .invFltr,
.invfldHLayer:hover > .invFltr:hover > ul.fltrDD,
.invfldHLayer:hover > .invFltr:hover > ul.fltrDD > li:hover > ul.fltrDD {
	display: block;
}
.invFltr ul.fltrDD {
	display: none;
	list-style: none;
	position: absolute;
	left: 0;
	top: 100%;
	border: solid 1px #e9eff4;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	width: 170px;
	background-color: #ffffff;
	-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
.invFltr ul.fltrDD li:first-child {
	border: none;
}
.invFltr ul.fltrDD li {
	border-top: solid 1px #f2f2f7;
	position: relative;
}
.invFltr ul.fltrDD li > button {
	display: block;
	width: 100%;
	padding: 6px 10px;
	position: relative;
	text-align: left;
}
.invFltr ul.fltrDD li > button > span {
	position: absolute;
	right: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.invFltr ul.fltrDD ul.fltrDD {
	top: 0;
	left: 100%;
	width: 120px;
}

.fltrDD li button.active {
	background-color: rgb(191 219 254);
}

@media all and (max-width: 767px) {
	.invFltr ul.fltrDD ul.fltrDD {
		top: 100%;
		left: 0;
		width: 100%;
	}
}

@media all and (min-width: 768px) {
	.invFltr ul.fltrDD ul.fltrDD {
		-webkit-border-radius: 0 6px 6px 6px;
		border-radius: 0 6px 6px 6px;
	}
	.invFieldsWrap
		> .cLoop:nth-last-child(-n + 5)
		> .invfldHLayer
		> .invFltr:hover
		> ul.fltrDD {
		top: auto;
		bottom: 90%;
	}
	.invFieldsWrap
		> .cLoop:nth-last-child(-n + 5)
		> .invfldHLayer
		> .invFltr:hover
		> ul.fltrDD
		> li:hover
		> ul.fltrDD {
		top: auto;
		bottom: 6%;
		-webkit-border-radius: 6px 6px 6px 0;
		border-radius: 6px 6px 6px 0;
	}
	.invFieldsWrap
		> .cLoop:nth-last-child(-n + 5)
		> .invfldHLayer
		.fltrHdr
		> .ddIco {
		-webkit-transform: scale(-1);
		transform: scale(-1);
	}
}

.boxTableCtnr {
	max-height: calc(100vh - 300px);
	width: 96%;
}
.addRow {
	position: absolute;
	left: -45px;
	bottom: 20px;
	-webkit-transform: rotate(269deg);
	transform: rotate(269deg);
}
.addCol {
	position: absolute;
	right: 12px;
	top: -22px;
}

.bgPrimary {
	background-color: var(--theme-primary);
}

.text2Lines {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 134px;
	display: block;
	font-size: 12px;
	/* display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; */
}

table.bxTable {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: 0;
	position: relative;
}
table.bxTable td {
	padding: 3px 4px;
	vertical-align: middle;
}

table.bxTable td.stik {
	position: sticky;
	left: 0;
	top: 0;
	z-index: 4;
	background-color: #ffffff;
}

.tdInputBx {
	position: relative;
}
.tdInputBx input.invFldInput {
	padding-right: 24px;
}
.tdInputBx .fltIco {
	position: absolute;
	top: 50%;
	right: 8px;
	z-index: 1;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	line-height: 1;
}

.lblBdr {
	border: solid 1px rgba(0, 0, 0, 0.4);
	padding: 4px 8px;
	font-size: inherit;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}
.compHldr {
	height: calc(100vh - 324px);
}


.ldBtn {
	margin: 0 10px 0 0;
	padding: 0;
	display: inline-block;
	position: relative;	
}

span.ld {
	width: 38px;
	height: 38px;
	border-radius: 50%;
	background-image: url('../../../public/svg/approve_like_thumb_icon.svg');
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
}

span.dis {
	transform: scale(-1);
}

.ldBtn > input[type='radio'] {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0;
}

.ldBtn input[type='radio']:checked + span.ld {
	background-image: url('../../../public/svg/approve_liked_thumb_icon.svg');
}

.oneLineText{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.lblBdr.und { 
	border-width: 0 0 1px;	
}